import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import { TestimonialGrid, TestimonialCard } from '../../components/TestimonialGrid'
import { PageTitle, PageTitle3, PageTitle2, PageParagraph } from '../../components/PageTitle'
import BenefitsCompounds from '../images/benefitsCompounds.svg'
import BenefitsEasy from '../images/benefitsEasy.svg'
import BenefitsEngaging from '../images/benefitsEngaging.svg'
import BenefitsSecure from '../images/benefitsSecure.svg'
import UnifiedExperience from '../images/unifiedExperience.svg'
import Default from '../../components/Layouts/Default';
import { VideoFrame } from '../../components/VideoFrame';
import DeviceLineup from '../images/deviceLineup.png';
import SetDisposition from '../images/setDisposition.png'
import SfNotes from '../images/sfNotes.svg'


import LibertyRing from '../animations/libertyRing.json'
import DesktopSalesforce from '../images/desktopSalesforce.png';
import SfHalf from '../images/sfHalf.png'



var lottie

export default class BetterEmail extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    lottie = require('lottie-web')
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LibertyRing,
      }

      lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Phone System</title>
        </Helmet>

        <WrapperBackground
          color={colors.trulyDark}
          background='headsetPerson3'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
                Video Meeting Recorder
              </PageTitle2>
              <PageTitle as="p" color={colors.white} mb="1.5rem" center>
                Record Your Web Meetings And Sync Them To Salesforce
              </PageTitle> 
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground center color={colors.trulyDark} background="managedByQ" gradientType="purple">
          <Section background={colors.trulyDark} flexDirection="column" maxWidth="100%">
            <Box>
                <PageTitle2 color={colors.white} mb="2rem" mt="2rem">
                Trusted By Category Defining Sales<br />& Customer Success Teams
              </PageTitle2>
            </Box>
            <TestimonialGrid>
              <TestimonialCard avatar="jackie" name="Jackie F" company="NewFront Insurance" quote="I love how you are able to listen to other colleagues calls while training. That live feature helped me significantly when I was training. I also like that you can see who is actively on a call."/>
              <TestimonialCard avatar="taylor" name="Taylor S" company="Cloud Kitchens" quote="Truly is great because it easily integrates with Salesforce to track my activity - both calls and texts. I also love the voicemail drop feature, as well as the mobile app that syncs flawlessly with my iPhone."/>
              <TestimonialCard avatar="billy" name="Billy B" company="Galvaninze" quote="I love how I can switch my number based on the purpose of my call and how easy Truly is to use. It's awesome I can listen to any of my colleagues phone calls at any time. It was a tremendous learning tool for me in my new job. The voicemail drop function is a game changer."/>
            </TestimonialGrid>
          </Section>
        </WrapperBackground>


        <WrapperBackground color={colors.trulyDark} background="businessScene1" gradientType="blueToGreen">
          <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1, 1 / 2]}>
              <PageTitle2 as="h2" color={colors.white} bold mb="1.5rem" center>
                Your Very Own, AI Powered NoteTaker
              </PageTitle2>
              <PageParagraph as="p" color={colors.white} mb="1.5rem">
                Now you can put all your focus on the customer conversation instead of taking notes.
                <br/><br/>
                Our meeting bot ensures every meeting automatically recorded, transcribed, analyzed and synced to your CRM, making it easily discoverable/searchable for later use.
                <br/><br/>
                And with our Revenue Process Automation framework, our bot can even automatically update key CRM fields without you having to lift a finger.

              </PageParagraph>
            </Box>

          </Section>
        </WrapperBackground>



        <WrapperSolid>
          <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
            <Box width={[1 / 2, 1 / 2]} p="2rem">
              <WidthImg src={UnifiedExperience} alt="Get Your Company Communicating" />
            </Box>
            <Box width={[1, 1 / 2]}>
              <PageTitle2 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
                One Unified Experience Across All Devices
              </PageTitle2>
              <PageParagraph as="p" color={colors.trulyDark} mb="1.5rem" center>
              Our meeting recorder doesn't require any change of workflow.
              <br/><br/>
              No matter how you choose to connect to your web meeting (desktop, conference bridge, mobile app), our bot will be there waiting for you.
              </PageParagraph>
            </Box>
          </Section>
        </WrapperSolid>


        <WrapperBackground color={colors.trulyDark} background="businessScene1" gradientType="darkLessTransparent">
          <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1, 1 / 2]}>
              <div
                ref={animationDiv => {
                  this.gaugeWebAnimation = animationDiv;
                }}
              />
            </Box>
            <Box width={[1, 1 / 2]}>
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
                Works Across Meeting Providers
              </PageTitle2>
              <PageParagraph as="p" color={colors.white} mb="1.5rem">
                Sales reps are often forced to use more than one meeting solution to accommodate customers and partners.  In many cases, these meetings can't be recorded and logged.
                <br/><br/>
                That's why we built our meeting bot to work with multiple meeting providers, so your reps can minimize friction for customers while still knowing that their data will be captured.

              </PageParagraph>
            </Box>
          </Section>
        </WrapperBackground>

      </Default>
    )
  }
}

const Values = styled.div`
  background-color: #222;
  font-family: 'Josefin Sans';
`

const Divider = styled.div`
  width: 100%;
  height: 1pt;
  background-color: rgba(255,255,255,0.3);
`
const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`

const VideoRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

const IconRow = styled.div`
  display: flex;
  justify-content: center;
`;